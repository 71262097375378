//user_manager.js
// Description: This file contains the user manager component which is used to manage users and their passwords.
// It uses the administration_context.js file to get the user data and post data to the API.

import { InputLabel, MenuItem, FormControl, Select, TextField, Input, Divider, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { post, useAdministrationContext } from "./administration_context";
import crypto from "crypto";
import { useAlertContext } from "../Page/alert_context";
import "../Main/default_component_style.css";
import "./user_manager.css";
import { useSidebarContext } from "../Sidebar/sidebar_context";

const UserManager = () => {
  const { user, setUser } = useAdministrationContext();
  const [password, setPassword] = useState("");
  const [newUsername, setNewUsername] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [users, setUsers] = useState([]);
  const { mode } = useSidebarContext();

  const { showAlert } = useAlertContext();

  useEffect(() => {
    const fetchData = async () => {
      const getUsers = await post({ method: "getUsers" });
      setUsers(getUsers);
    };

    fetchData();
  }, []);

  function getUsernameById(userId) {
    const user = users.find((user) => user[0] === userId);
    return user ? user[1] : "notfound"; // Returns the username if found, otherwise null
  }

  useEffect(() => {
    // console.log(users);
    if (users.length > 0) {
      showAlert(
        <span>
          Changed to user <strong>{getUsernameById(user)}</strong> (ID: {user})! Any changes made in Page Manager or Change password are made for this user!
        </span>,
        "warning"
      );
    }
    // console.log(user);
  }, [user]);

  const handleUserChange = (event) => {
    setUser(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleNewUsernameChange = (event) => {
    setNewUsername(event.target.value);
  };

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleAddUser = () => {
    if (newUsername.length < 1 || newPassword.length < 1) {
      //showAlert(<span><strong>Both username and password need to have entries!</strong></span>,"error");
      return;
    }
    const hashPassword = (password) => {
      const hash = crypto.createHash("sha256");
      hash.update(password);
      return hash.digest("hex");
    };

    const hashedPassword = hashPassword(newPassword);
    post({ method: "addUser", username: newUsername, password: hashedPassword });
    //showAlert(<span>new user <strong>{newUsername}</strong> added!</span>,"success");
  };

  const handleChangePassword = () => {
    console.log("Changing password for user:", user);
    if (password.length < 1) {
      //showAlert(<span><strong>Password needs to have an entry!</strong></span>,"error");
      return;
    }
    const hashPassword = (password) => {
      const hash = crypto.createHash("sha256");
      hash.update(password);
      return hash.digest("hex");
    };

    const hashedPassword = hashPassword(password);
    post({ method: "changePassword", user: user, password: hashedPassword });
    // console.log("User added:", newUsername, newPassword);
    //showAlert(<span><strong>Changing Password doesn't have an implementation yet! Will come soon!</strong></span>,"error");
  };

  return (
    <div className="user-manager">
      <div className="change-password-container">
        <div className="user-manager-header">
          <h2>Change Password</h2>
        </div>
        <div className="stack">
          <div className="sub-stack">
            <Typography variant="h6" className="label">
              {" "}
              User{" "}
            </Typography>

            <Select labelId="user-select-label" id="user-select" value={user} onChange={handleUserChange} autoWidth label="User">
              {users.map((user, index) => (
                <MenuItem key={index} value={user[0]}>
                  {user[1]}
                </MenuItem>
              ))}
            </Select>
          </div>

          <div className="sub-stack">
            <Typography variant="h6" className="label">
              {" "}
              New Password{" "}
            </Typography>
            <input
              type="password"
              className={`input ${mode === "light" ? "light-border" : "dark-border"}`}
              // label="New Password"
              // variant="outlined"
              value={password}
              onChange={handlePasswordChange}
            />
          </div>

          <Button className="Button" style={{ marginTop: "15px" }} onClick={handleChangePassword}>
            Submit
          </Button>
        </div>
      </div>

      <Divider sx={{ my: 2 }} />
      <div className="add-user-container">
        <div className="user-manager-header">
          <h2>Add User</h2>
        </div>

        <div className="stack">
          <div className="sub-stack">
            <Typography variant="h6" className="label">
              {" "}
              User Name{" "}
            </Typography>
            <input
              // label="Username"
              // variant="outlined"
              className={`input ${mode === "light" ? "light-border" : "dark-border"}`}
              value={newUsername}
              onChange={handleNewUsernameChange}
              // sx={{m: 1}}
            />
          </div>

          <div className="sub-stack">
            <Typography variant="h6" className="label">
              {" "}
              Password{" "}
            </Typography>
            <input className={`input ${mode === "light" ? "light-border" : "dark-border"}`} type="password" value={newPassword} onChange={handleNewPasswordChange} />
          </div>

          <Button className="Button" style={{ marginTop: "15px" }} onClick={handleAddUser}>
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UserManager;
